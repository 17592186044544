export const constants = {
  profile: {
    FETCH_PROFILE: 'FETCH_PROFILE'
  },
  testimonials: {
    ADD_TESTIMONIAL: 'ADD_TESTIMONIAL',
    FETCH_TESTIMONIALS: 'FETCH_TESTIMONIALS'
  },
  specialization: {
    FETCH_SPECIALIZATIONS_WITH_STACK: 'FETCH_SPECIALIZATIONS_WITH_STACK',
    FETCH_SPECIALIZATIONS: 'FETCH_SPECIALIZATIONS',
    FETCH_SPECIALIZATION_BY_ID: 'FETCH_SPECIALIZATION_BY_ID',
    ADD_SPECIALIZATION: 'ADD_SPECIALIZATION',
    UPDATE_SPECIALIZATION: 'UPDATE_SPECIALIZATION',
    DELETE_SPECIALIZATION: 'DELETE_SPECIALIZATION'
  },
  documents: {
    FETCH_DOCUMENTS: 'FETCH_DOCUMENTS'
  },
  partners: {
    ADD_PARTNERS: 'ADD_PARTNERS',
    FETCH_PARTNERS: 'FETCH_PARTNERS'
  },

  hr_applications: {
    FETCH_HRS: 'FETCH_HRS',
    FETCH_HR_BY_ID: 'FETCH_HR_BY_ID'
  },
  partner_applications: {
    FETCH_PARTNER_APPLICATIONS: 'FETCH_PARTNER_APPLICATIONS',
    FETCH_PARTNER_APPLICATIONS_BY_ID: 'FETCH_PARTNER_APPLICATIONS_BY_ID'
  },
  contacts: {
    FETCH_CONTACTS: 'FETCH_CONTACTS'
  },
  counters: {
    FETCH_COUNTERS: 'FETCH_COUNTERS'
  },
  posts: {
    FETCH_POSTS: 'FETCH_POSTS',
    ADD_POST: 'ADD_POST'
  },

  candidates: {
    FETCH_ALL_CANDIDATES: 'FETCH_ALL_CANDIDATES',
    FETCH_CANDIDATE_BY_ID: 'FETCH_CANDIDATE_BY_ID',
    CREATE_CANDIDATE: 'CREATE_CANDIDATE',
    UPDATE_CANDIDATE: ' UPDATE_CANDIDATE',
    FETCH_BY_SPECIALIZATION: 'FETCH_BY_SPECIALIZATION'
  },

  stack: {
    GET_STACK: 'GET_STACK',
    ADD_STACK: 'ADD_STACK'
  },
  gallery: {
    GET_IMAGES: 'GET_IMAGES',
    DELETE_IMAGE: 'DELETE_IMAGE',
    ADD_IMAGE: 'ADD_IMAGE'
  },
  cards: {
    GET_CARDS: 'GET_CARDS',
    DELETE_CARD: 'DELETE_CARD',
    ADD_CARD: 'ADD_CARD',
    GET_CARD_BY_ID: 'GET_CARD_BY_ID'
  },
  stories: {
    GET_STORIES: 'GET_STORIES',
    DELETE_STORY: 'DELETE_STORY',
    ADD_STORY: 'ADD_STORY',
    GET_STORY_BY_ID: 'GET_STORY_BY_ID'
  }
};
