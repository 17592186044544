export const defaultValues = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  company: '',
  country: '',
  specialization: '',
  message: '',
  hpot: ''
};
