import type { HTMLProps } from 'react';
import React from 'react';

interface SuccesIconProps extends HTMLProps<HTMLDivElement> {}
const Succes: React.FC<SuccesIconProps> = ({ className, ...rest }) => {
  return (
    <div className={`${className}`} {...rest}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="12" fill="#4DC760" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12C0 5.37321 5.37321 0 12 0C18.6268 0 24 5.37321 24 12C24 18.6268 18.6268 24 12 24C5.37321 24 0 18.6268 0 12ZM2.03571 12C2.03571 17.5018 6.49821 21.9643 12 21.9643C17.5018 21.9643 21.9643 17.5018 21.9643 12C21.9643 6.49821 17.5018 2.03571 12 2.03571C6.49821 2.03571 2.03571 6.49821 2.03571 12ZM15.7526 7.74023H17.0089C17.183 7.74023 17.2847 7.93845 17.1803 8.08041L11.5392 15.9018C11.4604 16.0119 11.3564 16.1016 11.236 16.1634C11.1156 16.2253 10.9822 16.2575 10.8468 16.2575C10.7114 16.2575 10.578 16.2253 10.4576 16.1634C10.3372 16.1016 10.2332 16.0119 10.1544 15.9018L6.81688 11.2733C6.7151 11.1313 6.81688 10.9331 6.99099 10.9331H8.24724C8.52314 10.9331 8.78028 11.067 8.94099 11.2893L10.8481 13.9358L15.0588 8.09648C15.2196 7.87148 15.4794 7.74023 15.7526 7.74023Z"
          fill="#020202"
        />
      </svg>
    </div>
  );
};

export default Succes;
